export const ALWAYS_REFETCH_DATA_AFTER_HOURS = 12; //12 hours
//
export const WORK_TYPES = {
  NORMAL: "0",
  TRANS: "trans",
  NO_DEPARTMENT: "NoDep",
  TRANSFER: "tr"
};

//CheckType
export const PUNCH_TYPES = {
  IN: "1",
  OUT: "2",
  IN_LUNCH: "3",
  OUT_LUNCH: "4"
};

export const LANGS = {
  FR: "fr",
  EN: "en"
};

const STRINGS_EN = {
  INDEX_BTN_PUNCH: "Mobile punch",
  INDEX_BTN_HOURS: "My working hours",
  INDEX_BTN_SCHEDULE: "My schedule",
  INDEX_BTN_BANK: "My bank hours",
  INDEX_BTN_CHAT: "My messages",
  INDEX_BTN_ABSENCE: "My calendar",
  INDEX_BTN_TIMESHEET: "My timesheet",

  MENU_PROFILE: "My Profile",
  MENU_REFRESH: "Refresh my Details",
  MENU_LOGOUT: "Logout",
  MENU_CHAT: "Chat",
  MENU_MESSAGES: "Messages",
  MENU_TIMER: "Timer",
  MENU_INDEX: "Homepage",

  SEND_MESSAGE: "Send",
  CREATE_THREAD: "Create an thread",

  STATE_IN_LUNCH: "Last Punch: Begin Lunch",
  STATE_PUNCH: "Last Punch: Begin Shift",
  STATE_WAITING: "Last Punch: End of Shift",

  NEED_INTERNET: "A connection to the internet is necessary",
  NO_INTERNET: "Connect to the internet to record your new data.",
  NO_INTERNET_POPUP:
    "You have un-recorded actions. You may not disconnect until you have a working internet connection.",
  IPAD_NO_INTERNET: "Connect to the internet to record your new punchs.",

  GREETING: "Hello",

  LOGIN_NIP: "Nip",
  LOGIN_COMPANY_CODE: "Company code",

  PROJECT_TITLE: "Project",
  ACTIVITY_TITLE: "Activity",
  OPERATION_TITLE: "Operation",

  SELECT_COMPANIES: "Please Choose an Companies",
  SELECT_PROJECTS: "Projects",
  SELECT_ACTIVITIES: "Activities",
  SELECT_OPERATIONS: "Operations",
  SELECT_BRANCH: "Branch",
  SELECT_DEPARTMENT: "Department",
  SELECT_DEPARTMENT_GROUP: "Department Group",
  SELECT_EMPLOYEE: "Employees",

  NOTE: "Note",
  YOUR_SUBJECT: "Chat subject",
  YOUR_MESSAGE: "Your message",

  NEW_MESSAGE: "You have a new message",

  BUTTON_TRANSFER: "Transfer",
  BUTTON_START_TIMER: "Punch IN",
  BUTTON_END_TIMER: "Punch OUT",
  BUTTON_START_LUNCH: "Begin Lunch",
  BUTTON_END_LUNCH: "End Lunch",

  LOCATION_CANT_SAVED: "Your position cannot be recorded",
  LOCATION_FORCED: ". And it is mandatory.",

  NEW_PROJECT_CREATED: "The new project was created",

  PUNCH_SAVED: "Your punch was recorded",
  LOCATION_NEEDED: "Your position is required.",
  SEARCH_TEXT: "Search",

  INSTALL_APP_BTN_IOS_BEFORE: "Click on ",
  INSTALL_APP_BTN_IOS_AFTER: "and add this website on your home screen.",
  INSTALL_APP_BTN: "Install",

  NO_RESULTS_FOUND: "No results have been found",
  PLEASE_SELECT_OPTION: "Please select an option",

  INSTALL_APP: "Install Info-Punch on your cellphone!",

  NO_LOCATION_SUPPORTED: "Your mobile device does not support geolocation",

  FORM_YES: "Yes",
  FORM_NO: "No",
  FORM_SUBMIT: "Submit",
  FORM_ANSWER: "Please answer",
  FORM_ANSWER_ALL: "Pleaser answer all questions",

  VALIDATION_ERROR_PROJECT: "The project can't be empty",
  VALIDATION_ERROR_ACTIVITY: "The activity can't be empty",
  VALIDATION_ERROR_BRANCH: "The branch can't be empty",
  VALIDATION_ERROR_DEPARTMENT: "The department can't be empty",
  VALIDATION_ERROR_DEPARTMENT_GROUP: "The department group can't be empty",
  VALIDATION_ERROR_OPERATION: "The operation can't be empty",
  VALIDATION_ERROR_EMPLOYEE: "The employee can't be empty",

  VALIDATION_ERROR_NEW_MESSAGE: "Your message can't be empty",

  VALIDATION_ERROR_NIP: "The NIP can't be empty",
  VALIDATION_ERROR_COMPANY: "The company code can't be empty",
  VALIDATION_ERROR_LENGTH_COMPANY: "The company code must be over 6 characters",

  VALIDATION_ERROR_GLOBAL: "You need to select an option above.",
  ERROR_PROBLEM: "Problem",
  ERROR_VERIFY: "PIN or Company code is invalid",
  ERROR_LOGIN_VERIFY: "Please verify your login information",

  PLEASE_REFRESH: "Please refresh your application, a new version is available.",

  COPYRIGHT: "All right reversed Info-Punch",

  FLASH_DEFAULT: "You got a new message",

  QUIZ_HEADER: "Veuillez répondre à ces questions.",

  PAD_BTN_EFF: "DEL",
  PAD_BTN_SUBMIT: "LOGIN",
  PAD_BTN_AUTOPUNCH_SUBMIT: "PUNCH",

  MESSAGES: "Messages",

  NEED_PROJECT_FIRST: "Please select an project before",
  BARCODE_INVALID: "The barcode scanned is invalid",

  QUESTION_1: "Do I have any of the symptoms: cough, fever, difficulty breathing, sudden loss of smell?",
  QUESTION_2: "Have I returned from a trip outside the country for less than 2 weeks?",
  QUESTION_3: "Am I in contact with someone who has COVID-19?"
};

const STRINGS_FR = {
  INDEX_BTN_PUNCH: "Punch Mobile",
  INDEX_BTN_HOURS: "Mes heures travaillées",
  INDEX_BTN_SCHEDULE: "Mon horaire",
  INDEX_BTN_BANK: "Mes soldes de banque",
  INDEX_BTN_CHAT: "Messagerie",
  INDEX_BTN_ABSENCE: "Demande de congé et de disponibilité",
  INDEX_BTN_TIMESHEET: "Feuille de temps",

  MENU_PROFILE: "Mon Dossier / My Profile",
  MENU_REFRESH: "Rafraichir les informations",
  MENU_LOGOUT: "Déconnexion",
  MENU_CHAT: "Messagerie",
  MENU_MESSAGES: "Messages",
  MENU_TIMER: "Punch",
  MENU_INDEX: "Accueil",

  SEND_MESSAGE: "Envoyer",
  CREATE_THREAD: "Créer une discussion",

  STATE_IN_LUNCH: "dern. punch: Début lunch",
  STATE_PUNCH: "dern. punch: début",
  STATE_WAITING: "dern. punch: fin quart",

  NEED_INTERNET: "Une connexion internet est nécessaire",
  NO_INTERNET: "Connectez-vous à internet pour enregistrer vos nouvelles données.",
  NO_INTERNET_POPUP:
    "Vous avez des actions non-enregistré, vous ne pouvez pas vous deconnecter avant que vous ayez une connection internet.",
  IPAD_NO_INTERNET: "Connectez-vous à internet pour enregistrer vos nouveaux punchs.",

  GREETING: "Bonjour",

  LOGIN_NIP: "Nip",
  LOGIN_COMPANY_CODE: "Code de compagnie",

  PROJECT_TITLE: "Projet",
  ACTIVITY_TITLE: "Activité",
  OPERATION_TITLE: "Opération",

  SELECT_COMPANIES: "Veuillez choisir une Compagnie",
  SELECT_PROJECTS: "Projets",
  SELECT_ACTIVITIES: "Activités",
  SELECT_OPERATIONS: "Opérations",
  SELECT_BRANCH: "Succursalle",
  SELECT_DEPARTMENT: "Département",
  SELECT_DEPARTMENT_GROUP: "Groupe de Département",
  SELECT_EMPLOYEE: "Employés",

  ENTER_NIP: "Veuillez entrer votre nip d'utilisateur",
  ENTER_COMPANY_CODE: "Veuillez entrer votre code de compagnie",
  LOGOUT: "Se deconnecter",
  COMPANY_CODE: "Compagnie",
  CONNECTED: "Connecté",

  NOTE: "Note",
  YOUR_SUBJECT: "Sujet de la discussion",
  YOUR_MESSAGE: "Votre message",

  NEW_MESSAGE: "Vous avez un nouveau message. Veuillez utiliser l'application mobile pour y répondre",

  BUTTON_TRANSFER: "Transfert",
  BUTTON_START_TIMER: "Début Quart",
  BUTTON_END_TIMER: "Fin Quart",
  BUTTON_START_LUNCH: "Début Lunch",
  BUTTON_END_LUNCH: "Fin Lunch",

  LOCATION_CANT_SAVED: "Votre position ne peut pas s'enregistrer",
  LOCATION_FORCED: ". Et elle est obligatoire.",

  NEW_PROJECT_CREATED: "Le nouveau projet a été créé.",

  PUNCH_SAVED: "Votre punch à été enregistré",
  LOCATION_NEEDED: "Votre position est nécessaire",
  SEARCH_TEXT: "Recherche",

  INSTALL_APP_BTN_IOS_BEFORE: "Cliquer sur",
  INSTALL_APP_BTN_IOS_AFTER: "et ajouter ce site sur votre écran d'accueil.",
  INSTALL_APP_BTN: "Installer",

  NO_RESULTS_FOUND: "Aucun résultats n'a été trouvé",
  PLEASE_SELECT_OPTION: "Veuillez sélectionner une option",

  INSTALL_APP: "Installer Info-Punch sur votre cellulaire!",

  NO_LOCATION_SUPPORTED: "Votre appareil mobile ne supporte pas la géolocalisation",

  FORM_YES: "Oui",
  FORM_NO: "Non",
  FORM_SUBMIT: "Soumettre",
  FORM_ANSWER: "Veuillez répondre",
  FORM_ANSWER_ALL: "Veuillez répondre à toute les questions.",

  VALIDATION_ERROR_PROJECT: "Le projet ne peut pas être vide",
  VALIDATION_ERROR_ACTIVITY: "L'activité ne peut pas être vide",
  VALIDATION_ERROR_BRANCH: "La succursale ne peut pas être vide",
  VALIDATION_ERROR_DEPARTMENT: "Le département ne peut pas être vide",
  VALIDATION_ERROR_DEPARTMENT_GROUP: "Le groupe de département ne peut pas être vide",
  VALIDATION_ERROR_OPERATION: "L'opération ne peut pas être vide",
  VALIDATION_ERROR_EMPLOYEE: "L'employé ne peut pas être vide",

  VALIDATION_ERROR_NEW_MESSAGE: "Votre message ne peut pas être vide",

  VALIDATION_ERROR_GLOBAL: "Veuillez choisir une option d'envoi",
  VALIDATION_ERROR_NIP: "Le NIP ne peut pas être vide",
  VALIDATION_ERROR_COMPANY: "Le numéro de compagnie ne peut pas être vide",
  VALIDATION_ERROR_LENGTH_COMPANY: "Le numéro de compagnie est plus long que 6 charactères",

  ERROR_PROBLEM: "Problème",
  ERROR_VERIFY: "Veuillez verifier vos informations",
  ERROR_LOGIN_VERIFY: "Veuillez verifier vos informations de connexion",

  PLEASE_REFRESH: "Veuillez rafraichir l'application. Une nouvelle version est disponible.",

  COPYRIGHT: "Tous droits réservés Info-Punch",

  FLASH_DEFAULT: "Vous avez un message",

  QUIZ_HEADER: "Please answer all questions.",

  PAD_BTN_EFF: "EFF",
  PAD_BTN_SUBMIT: "CONNECTER",
  PAD_BTN_AUTOPUNCH_SUBMIT: "PUNCHER",

  MESSAGES: "Messages",

  NEED_PROJECT_FIRST: "Veuillez sélectionner un projet en premier",
  BARCODE_INVALID: "Le code barre scanné n'est pas valide.",

  QUESTION_1: "Est-ce que j'ai un des symptômes : toux, fièvre, difficultés respiratoires, perte soudaine de l'odorat?",
  QUESTION_2: "Est-ce que je reviens d'un voyage à l'extérieur du pays depuis moins de 2 semaines?",
  QUESTION_3: "Est-ce que je suis en contact avec une personne atteinte de la COVID-19?"
};

export const STRINGS = (lang = "fr") => {
  if (lang === "en") {
    return STRINGS_EN;
  }

  return STRINGS_FR;
};

export const THREAD_NAME_TYPES = {
  DISCUSSION: "DISCUSSION",
  PROJECT: "PROJECT",
  DEPARTMENT: "DEPARTMENT",
  BRANCH: "BRANCH",
  RECIPIENTS: "RECIPIENTS"
};

export const APP_TYPES = {
  MOBILE: "mobile",
  IPAD: "ipad"
};
