import EventBus, { EVENTS } from "globals/utils/eventbus";
import debounce from "lodash-es/debounce";
import * as globalTypes from "globals/store/types";

const isOnlineMixin = {
  data() {
    return {
      IS_ONLINE: true
    };
  },
  created() {
    this.pollingId = null;

    this.changeIsOnline = this.changeIsOnline.bind(this);
    this.checkIfOnline = this.checkIfOnline.bind(this);

    this.changeIsOnline(this.IS_ONLINE);
    this.checkIfOnline();
    this.startPolling();

    //when online/offline change just our custom check to figure it out
    window.addEventListener("online", this.debounceCheckIfOnline);
    window.addEventListener("offline", this.debounceCheckIfOnline);
  },
  beforeDestroy() {
    this.stopPolling();
    window.removeEventListener("online", this.debounceCheckIfOnline);
    window.removeEventListener("offline", this.debounceCheckIfOnline);
  },
  methods: {
    stopPolling() {
      clearTimeout(this.pollingId);
    },
    startPolling() {
      this.pollingId = setInterval(() => {
        this.checkIfOnline();
      }, 10000); //10 SEC
    },
    debounceCheckIfOnline: debounce(
      function () {
        this.checkIfOnline();
      },
      100,
      { leading: true, trailing: false }
    ),
    checkIfOnline() {
      let _img = document.createElement("img");
      _img.style.opacity = 0;
      _img.style.visibility = "hidden";
      _img.style.position = "absolute";
      _img.style.top = "-100px";
      _img.style.left = "-100px";
      _img.style.width = "1px";
      _img.style.height = "1px";

      //always randomize the url so no cache
      _img.src = `/img/favicon.ico?ver=${new Date().getTime()}`;

      _img.onload = () => {
        this.changeIsOnline(true);
        document.body.removeChild(_img);
      };
      _img.onerror = (err) => {
        this.changeIsOnline(false);
        document.body.removeChild(_img);
      };

      document.body.appendChild(_img);
    },
    changeIsOnline(isOnline) {
      if (isOnline === this.IS_ONLINE) return; //dont update if didnt change

      this.IS_ONLINE = isOnline;
      this.$store.commit(globalTypes.SET_IS_ONLINE, isOnline);

      EventBus.$emit(EVENTS.IS_ONLINE, isOnline);
    }
  }
};

export default isOnlineMixin;
